import React from 'react';
import { animateScroll as scroll } from 'react-scroll';
import { FaYoutube, FaTwitter, FaTwitch, FaFacebook } from 'react-icons/fa';
import {
    FooterContainer,
    FooterWrap,
    FooterLinksContainer,
    FooterLinksWrapper,
    FooterLinkItems,
    FooterLinkTitle,
    FooterLink,
    SocialMedia,
    SocialMediaWrap,
    SocialLogo,
    WebsiteRights,
    SocialIcons,
    SocialIconLink,
}
    from './FooterElements';

const Footer = () => {

    //function that scrolls to top of page
    const toggleHome = () => {
        scroll.scrollToTop();
    };

    return (
        <FooterContainer id='footer'>
            <FooterWrap>
                <FooterLinksContainer>
                    <FooterLinksWrapper>
                        <FooterLinkItems>
                            <FooterLinkTitle>Learn About AWS</FooterLinkTitle>
                            <FooterLink to="/learn/aws">What is AWS?</FooterLink>
                            <FooterLink to="/learn/cloud-computing">What is Cloud Computing?</FooterLink>
                            <FooterLink to="/learn/devops">What is DevOps?</FooterLink>
                            <FooterLink to="/learn/containers">What is a Container?</FooterLink>
                            <FooterLink to="/learn/data-lake">What is a Data Lake?</FooterLink>
                            <FooterLink to="/learn/cloud-security">AWS Cloud Security</FooterLink>
                            <FooterLink to="/learn/whats-new">What's New</FooterLink>
                            <FooterLink to="/learn/blogs">Blogs</FooterLink>
                            <FooterLink to="/learn/press-releases">Press Releases</FooterLink>
                        </FooterLinkItems>
                        <FooterLinkItems>
                            <FooterLinkTitle>Resources for AWS</FooterLinkTitle>
                            <FooterLink to="/resources/getting-started">Getting Started</FooterLink>
                            <FooterLink to="/resources/trainings">Training and Certification</FooterLink>
                            <FooterLink to="/resources/portfolio">AWS Solutions Portfolio</FooterLink>
                            <FooterLink to="/resources/architecture">Architecture Center</FooterLink>
                            <FooterLink to="/resources/product">Product and Technical FAQs</FooterLink>
                            <FooterLink to="/resources/analyst-reports">Analyst Reports</FooterLink>
                            <FooterLink to="/resources/apn">AWS Partner Network</FooterLink>
                        </FooterLinkItems>
                    </FooterLinksWrapper>
                    <FooterLinksWrapper>
                        <FooterLinkItems>
                            <FooterLinkTitle>Partner Resources</FooterLinkTitle>
                            <FooterLink to="/partner/vmware">VMWare YouTube Channel</FooterLink>
                            <FooterLink to="/partner/rosa">Red Hat OpenShift on AWS YouTube Channel</FooterLink>
                            <FooterLink to="/partner/sap">SAP YouTube Channel</FooterLink>
                        </FooterLinkItems>
                        <FooterLinkItems>
                            <FooterLinkTitle>Help</FooterLinkTitle>
                            <FooterLink to="/help/contact">Contact Us</FooterLink>
                            <FooterLink to="/help/careers">AWS Careers</FooterLink>
                            <FooterLink to="/help/ticket">File a Support Ticket</FooterLink>
                            <FooterLink to="/help/knowlegde-center">Knowledge Center</FooterLink>
                            <FooterLink to="/help/support">AWS Support Overview</FooterLink>
                            <FooterLink to="/help/legal">Legal</FooterLink>
                        </FooterLinkItems>
                    </FooterLinksWrapper>
                </FooterLinksContainer>
                <SocialMedia>
                    <SocialMediaWrap>
                        <SocialLogo to="/" onClick={toggleHome}>
                            AWS Partner Catalogs
                        </SocialLogo>
                        <WebsiteRights>© {new Date().getFullYear()}, Amazon Web Services, Inc. or its affiliates. All rights reserved</WebsiteRights>
                        <SocialIcons>
                            <SocialIconLink href="/social/youtube" target="_blank" aria-label="YouTube">
                                <FaYoutube />
                            </SocialIconLink>
                            <SocialIconLink href="/social/twitter" target="_blank" aria-label="Twitter">
                                <FaTwitter />
                            </SocialIconLink>
                            <SocialIconLink href="/social/facebook" target="_blank" aria-label="LinkedIn">
                                <FaFacebook />
                            </SocialIconLink>
                            <SocialIconLink href="/social/twitch" target="_blank" aria-label="LinkedIn">
                                <FaTwitch />
                            </SocialIconLink>
                        </SocialIcons>
                    </SocialMediaWrap>
                </SocialMedia>
            </FooterWrap>

        </FooterContainer>
    )
}

export default Footer
