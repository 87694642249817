import React from 'react';
import './App.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Home from './pages'

function App() {

  return (
    <Router>
      <Switch>
        <Route path="/" component={Home} exact />
        <Route path='/learn/aws' component={() => {
          window.location.href = 'https://aws.amazon.com/what-is-aws/?nc1=f_cc?onbrand';
          return null;
        }} />
        <Route path='/learn/cloud-computing' component={() => {
          window.location.href = 'https://aws.amazon.com/what-is-cloud-computing/?nc1=f_cc?onbrand';
          return null;
        }} />
        <Route path='/learn/devops' component={() => {
          window.location.href = 'https://aws.amazon.com/devops/what-is-devops/?nc1=f_cc?onbrand';
          return null;
        }} />
        <Route path='/learn/containers' component={() => {
          window.location.href = 'https://aws.amazon.com/containers/?nc1=f_cc?onbrand';
          return null;
        }} />
        <Route path='/learn/data-lake' component={() => {
          window.location.href = 'https://aws.amazon.com/big-data/datalakes-and-analytics/what-is-a-data-lake/?nc1=f_cc?onbrand';
          return null;
        }} />
        <Route path='/learn/cloud-security' component={() => {
          window.location.href = 'https://aws.amazon.com/security/?nc1=f_cc?onbrand';
          return null;
        }} />
        <Route path='/learn/whats-new' component={() => {
          window.location.href = 'https://aws.amazon.com/new/?nc1=f_cc?onbrand';
          return null;
        }} />
        <Route path='/learn/blogs' component={() => {
          window.location.href = 'https://aws.amazon.com/blogs/?nc1=f_cc?onbrand';
          return null;
        }} />
        <Route path='/learn/press-releases' component={() => {
          window.location.href = 'https://press.aboutamazon.com/press-releases/aws';
          return null;
        }} />

        <Route path="/resources/getting-started" component={() => {
          window.location.href = 'https://aws.amazon.com/getting-started/?nc1=f_cc?onbrand';
          return null;
        }} />
        <Route path="/resources/trainings" component={() => {
          window.location.href = 'https://aws.amazon.com/training/?nc1=f_cc?onbrand';
          return null;
        }} />
        <Route path="/resources/portfolio" component={() => {
          window.location.href = 'https://aws.amazon.com/solutions/?nc1=f_cc?onbrand';
          return null;
        }} />
        <Route path="/resources/architecture" component={() => {
          window.location.href = 'https://aws.amazon.com/architecture/?nc1=f_cc?onbrand';
          return null;
        }} />
        <Route path="/resources/product" component={() => {
          window.location.href = 'https://aws.amazon.com/faqs/?nc1=f_dr?onbrand';
          return null;
        }} />
        <Route path="/resources/analyst-reports" component={() => {
          window.location.href = 'https://aws.amazon.com/resources/analyst-reports/?nc1=f_cc?onbrand';
          return null;
        }} />
        <Route path="/resources/apn" component={() => {
          window.location.href = 'https://aws.amazon.com/partners/?nc1=f_dr?onbrand';
          return null;
        }} />
        <Route path="/partner/vmware" component={() => {
          window.location.href = 'https://www.youtube.com/watch?v=iA6wKPpPw_s&list=PLhr1KZpdzukf71uiGXeavQb7yrYhbqK3F';
          return null;
        }} />
        <Route path="/partner/rosa" component={() => {
          window.location.href = 'https://www.youtube.com/playlist?list=PLhr1KZpdzukc5X53T27sDSBK7myDVwYqj';
          return null;
        }} />
        <Route path="/partner/sap" component={() => {
          window.location.href = 'https://www.youtube.com/playlist?list=PLhr1KZpdzukdbD6WFSe_tPVSMFEELyKI0';
          return null;
        }} />
        <Route path="/help/contact" component={() => {
          window.location.href = 'https://aws.amazon.com/contact-us/?nc1=f_m?onbrand';
          return null;
        }} />
        <Route path="/help/careers" component={() => {
          window.location.href = 'https://aws.amazon.com/careers/?nc1=f_hi?onbrand';
          return null;
        }} />
        <Route path="/help/ticket" component={() => {
          window.location.href = 'https://console.aws.amazon.com/support/home/?nc1=f_dr';
          return null;
        }} />
        <Route path="/help/knowlegde-center" component={() => {
          window.location.href = 'https://aws.amazon.com/premiumsupport/knowledge-center/?nc1=f_dr?onbrand';
          return null;
        }} />
        <Route path="/help/support" component={() => {
          window.location.href = 'https://aws.amazon.com/premiumsupport/?nc1=f_dr?onbrand';
          return null;
        }} />
        <Route path="/help/legal" component={() => {
          window.location.href = 'https://aws.amazon.com/legal/?nc1=f_cc?onbrand';
          return null;
        }} />
        <Route path="/social/youtube" component={() => {
          window.location.href = 'https://www.youtube.com/channel/UCd6MoB9NC6uYN2grvUNT-Zg';
          return null;
        }} />
        <Route path="/social/twitter" component={() => {
          window.location.href = 'https://twitter.com/awscloud';
          return null;
        }} />
        <Route path="/social/facebook" component={() => {
          window.location.href = 'https://www.facebook.com/amazonwebservices';
          return null;
        }} />
        <Route path="/social/twitch" component={() => {
          window.location.href = 'https://www.twitch.tv/aws';
          return null;
        }} />
      </Switch>
    </Router>
  );
}

export default App;