import styled from 'styled-components';

export const ServicesHeaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    background: #232F3E;
    height: 380px;


    @media screen and (max-width: 2048px) {
        height: 330px;
    }

    @media screen and (max-width : 540px) {
        height: 380px;
        padding-left: 10px;
    }
`;

export const ServicesHeaderIntro = styled.div`
   width: 700px;

   @media screen and (max-width : 540px) {
       width: 400px;
    }
`;

export const ServicesContainer = styled.div`
    height: 1300px;
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #232F3E;

    @media screen and (max-width: 1024px) {
        height: 1900px;
        margin-top: 0;
    }

    @media screen and (max-width : 540px) {
        height: 2800px;
    }
`;

export const ServicesWrapper = styled.div`
    max-width: 1000px;
    
    margin: 0 auto;
    margin-top: -70px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    grid-gap: 16px;

    @media screen and (max-width: 1000px) {
        grid-template-columns: 1fr 1fr;
        padding: 0 20px;
    }

    @media screen and (max-width: 768px) {
        grid-template-columns: 1fr;
        padding: 0 20px;
    }
`;

export const ServicesCard = styled.div`
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-radius: 10px;
    max-height: 340px;
    padding: 30px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.2);
    transition: all 0.2s ease-in-out;

    &:hover {
        transform: scale(1.02);
        transition: all 0.2s ease-in-out;
        cursor: pointer;
    }
`;

export const ServicesCardTitle = styled.h2`
    font-size: 1rem;
    font-weight: bold; 
    margin-bottom: 10px;
`;

export const ServicesIcon = styled.img`
    height: ${props => props.height ? props.height : '144px'};
    width: ${props => props.width ? props.width : '256px'};
    margin-bottom: 10px;
`;

export const ServicesH1 = styled.h1`
    letter-spacing: -.005em;
    font-weight: 400;
    font-size: 2.5em;
    line-height: 48px;
    color: #fff;

    @media screen and (max-width: 480px) {
        font-size: 1.5rem;
        margin-left: 10px;
    }
`;

export const ServicesH2 = styled.h2`
    font-size: 1rem;
    color: #fff;
    margin-top: 10px;
    margin-bottom: 34px;

    @media screen and (max-width : 480px) {
        margin-left: 10px;
        margin-right: 10px;
        font-size: 0.9rem;
    }
`;

export const ServicesHeaderSubtitle = styled.h2`
    font-size: 1rem;
    font-weight: bold;
    color: #fff;
    margin-top: 10px;
    margin-bottom: 10px;

    @media screen and (max-width : 480px) {
        margin-left: 10px;
        margin-bottom: 5px;
        margin-right: 10px;
        font-size: 0.9rem;
    }
`;

export const ServicesP = styled.p`
    font-size: 1rem;
    text-align: center;
`;