import React from 'react';
import IconCanonical from '../../images/canonical.png';
import IconIbm from '../../images/ibm.png';
import IconMicrosoft from '../../images/microsoft.png';
import IconOracle from '../../images/oracle.png';
import IconRedHat from '../../images/redhat.png';
import IconSap from '../../images/sap.png';
import IconSuse from '../../images/suse.png';
import IconVmware from '../../images/vmware.png';

import {
    ServicesContainer,
    ServicesH1,
    ServicesWrapper,
    ServicesCard,
    ServicesIcon,
    ServicesH2,
    ServicesP,
    ServicesCardTitle,
    ServicesHeaderContainer,
    ServicesHeaderIntro,
    ServicesHeaderSubtitle
} from './ServicesElements';

const Services = () => {
    return (
        <>
            <ServicesContainer id="services">

                <ServicesHeaderContainer>
                    <ServicesH1>AWS Partner Catalogs</ServicesH1>
                    <ServicesHeaderSubtitle>Learn about AWS Partner solutions to accelerate your journey to the cloud</ServicesHeaderSubtitle>

                    <ServicesHeaderIntro>
                        <ServicesH2>Get value faster with AWS Partner Solutions. Explore our Partner Catalogs to stay up to date and learn from our AWS Partner Solutions Architects through architectural best practices, readiness materials, sales and technical content, and upcoming AWS events.</ServicesH2>
                    </ServicesHeaderIntro>
                </ServicesHeaderContainer>


                <ServicesWrapper>
                    <ServicesCard onClick={() => {
                        window.location.href = 'https://microsoft.winonaws.cloud';
                        return null;
                    }}>
                        <ServicesIcon src={IconMicrosoft} />
                        <ServicesCardTitle>Microsoft on AWS</ServicesCardTitle>
                        <ServicesP>Migrate, extend, and develop Microsoft-based web, mobile and data on AWS</ServicesP>
                    </ServicesCard>

                    <ServicesCard onClick={() => {
                        window.location.href = 'https://redhat.winonaws.cloud';
                        return null;
                    }}>
                        <ServicesIcon src={IconRedHat} />
                        <ServicesCardTitle>Red Hat on AWS</ServicesCardTitle>
                        <ServicesP>Secure, and easy to manage workloads with Red Hat OpenShift on AWS, RHEL and Ansible</ServicesP>
                    </ServicesCard>
                    <ServicesCard onClick={() => {
                        window.location.href = 'https://sap.winonaws.cloud';
                        return null;
                    }}>
                        <ServicesIcon src={IconSap} />
                        <ServicesCardTitle>SAP on AWS</ServicesCardTitle>
                        <ServicesP>Drive new levels of efficiency and innovation by running your SAP applications on AWS</ServicesP>
                    </ServicesCard>
                    <ServicesCard onClick={() => {
                        window.location.href = 'https://vmware.winonaws.cloud';
                        return null;
                    }}>
                        <ServicesIcon src={IconVmware} />
                        <ServicesCardTitle>VMware Cloud on AWS</ServicesCardTitle>
                        <ServicesP>The Fastest, Safest Path for migrating and extendingall your VMware Workloads on AWS</ServicesP>
                    </ServicesCard>
                    <ServicesCard onClick={() => {
                        window.location.href = 'https://suse.winonaws.cloud';
                        return null;
                    }}>
                        <ServicesIcon src={IconSuse} />
                        <ServicesCardTitle>SUSE and Rancher on AWS</ServicesCardTitle>
                        <ServicesP>A pioneer in open source, software-defined infrastructure and application solutions</ServicesP>
                    </ServicesCard>

                    <ServicesCard onClick={() => {
                        window.location.href = 'https://canonical.winonaws.cloud';
                        return null;
                    }}>
                        <ServicesIcon src={IconCanonical} />
                        <ServicesCardTitle>Canonical on AWS</ServicesCardTitle>
                        <ServicesP>Ubuntu Pro on AWS, the builder’s OS of choice, starting now with Ubuntu Pro on AWS</ServicesP>
                    </ServicesCard>

                    <ServicesCard onClick={() => {
                        window.location.href = 'https://ibm.winonaws.cloud';
                        return null;
                    }}>
                        <ServicesIcon src={IconIbm} />
                        <ServicesCardTitle>IBM on AWS</ServicesCardTitle>
                        <ServicesP>Bring IBM Data, AI, Security, Automation Tools and Applications to AWS</ServicesP>
                    </ServicesCard>

                    <ServicesCard onClick={() => {
                        window.location.href = 'https://oracle.winonaws.cloud';
                        return null;
                    }} 
                    // style={{
                    //     opacity: 0.5,
                    //     pointerEvents: "none"
                    // }}
                    >
                        <ServicesIcon src={IconOracle} />
                        <ServicesCardTitle>Oracle on AWS</ServicesCardTitle>
                        <ServicesP>Enterprise-grade apps on AWS using database and middleware software by Oracle</ServicesP>
                    </ServicesCard>



                </ServicesWrapper>
            </ServicesContainer>
        </>)
}

export default Services
